.App {
    width: 100%;
    max-width: 1920px;
    position: relative;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.contentFlex {
    display: flex;
}

.menuButton {
    color: #919ebd;
    padding-right: 0px;
    padding-left: 30px;
}

#root {
    height: 100%;
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}

.MuiCollapse-wrapperInner {
    width: 100% !important;
}
